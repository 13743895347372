import {
  HTMLProps,
  PropsWithChildren,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import clsx from 'clsx';
import merge from 'lodash.merge';
import 'swiper/css';
import { Swiper } from 'swiper';
import { Autoplay } from 'swiper/modules';

type SliderContext = {
  slider?: Swiper | null;
  setSlider?: (swiper: Swiper) => void;
};

const SliderContext = createContext<SliderContext>({});

import { SwiperOptions } from 'swiper/types/swiper-options';

import SliderNavigation from 'src/components/common/slider-navigation';

import useSliderMemory from 'src/hooks/use-slider-memory';

/**
 * SliderProvider should always be wrapped around a Slider component.
 *
 * This will make the SwiperJS instance available to all child nodes of this
 * component by using `useSlider()
 **/
export function SliderProvider({ children }: { children: ReactNode }) {
  const [slider, setSlider] = useState<SliderContext['slider']>(null);
  const context = useMemo(() => {
    return { slider, setSlider };
  }, [slider, setSlider]);

  return <SliderContext.Provider value={context}>{children}</SliderContext.Provider>;
}

/**
 * Returns the value of an enclosing SliderProvider
 */
export function useSlider(): SliderContext {
  const context = useContext(SliderContext);
  if (context === undefined) {
    throw new Error(`useSlider must be used within a SliderContext`);
  }
  return context;
}

export type SliderProps = PropsWithChildren<HTMLProps<HTMLDivElement>> & {
  /**
   * whether left-right navigation will be included with the slider
   */
  withNavigation?: boolean;
  /**
   * Any of the swiperJS parameters that will be passed to the Swiper
   * constructor and later update the instance (if dynamically options are dynamically
   * set) https://swiperjs.com/swiper-api#parameters
   * */
  options?: SwiperOptions;
};

export function Slider(props: SliderProps) {
  const { slider, setSlider } = useSlider();
  const [initialSlide, setInitialSlide] = useSliderMemory(props.id || 'slider');
  const { className = '', children, options, withNavigation, ...rest } = props;

  const swiperHandlers = useMemo(
    () => ({
      initialSlide,
      on: {
        init: (swiperInstance: Swiper) => {
          // remember the slide we have started with,
          // in case we have returned to the advanced slider already
          setInitialSlide(swiperInstance.realIndex);
        },
        slideChange: (swiperInstance: Swiper) => {
          // remember the slide progress
          setInitialSlide(swiperInstance.realIndex);
        },
      },
    }),
    [initialSlide, setInitialSlide],
  );

  const ref = (sliderContainer: HTMLDivElement) => {
    // do not init the swiper more than once (it’s possible and
    // it will mess things up)
    if (!slider && sliderContainer && setSlider) {
      setSlider(
        new Swiper(sliderContainer, {
          slidesPerView: 'auto',
          ...swiperHandlers,
          ...options,
          modules: [Autoplay],
        }),
      );
    }
  };

  // sync swiper settings
  useEffect(() => {
    // NOTE: when applying options to existing slider’s params, we must not override
    // existing values (so, not to use `Object.assign`), as `slider.params` contains
    // all of the default options, overriding which causes trouble.
    if (slider) {
      merge(slider.params, options);
      slider.update();
    }
  }, [slider, options]);

  // Note:
  // With web-components, you can only pass arguments that can be serialized to string,
  // no nested objects. "props" become html arguments and have to use kebab-case over
  // camelCase.  In order to pass complicated params we should rely on the assigning
  // of the wrappers’ props to the swiper-element instance (see useEffect above)

  // IMPORTANT: The `swiper` and `swiper-wrapper` CSS classes are required
  // for the Swiper library to function properly – we are using the global
  // styles from swiper (see the imports section at the top of the file)
  // @todo [swiper@>=12]: Verify if this class name remains unchanged in the
  // upcoming Swiper version upgrade or if a way to customise the class names
  // is provided
  return (
    <div className={clsx('slider swiper', className)} ref={ref} {...rest}>
      <div className="swiper-wrapper">{children}</div>
      {withNavigation ? <SliderNavigation /> : null}
    </div>
  );
}

export function SliderSlide(props: PropsWithChildren<HTMLProps<HTMLDivElement>>) {
  const { children, className } = props;
  // IMPORTANT: See the note about the hardcoded CSS class names above
  // @todo [swiper@>=12]: Verify if this class name remains unchanged in the
  //  upcoming Swiper version upgrade or if a way to customise the class names
  // is provided
  return <div className={clsx('swiper-slide', className)}>{children}</div>;
}
