import { SVGProps } from 'react';
import styles from 'src/components/common/slider-navigation.module.css';
import useSliderNavigation from 'src/hooks/use-slider-navigation';
import useTranslate from 'src/hooks/use-translate';

function Arrow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" aria-hidden {...props}>
      <circle cx="24" cy="24" r="24" className="text-white" fill="currentColor" />
      <path
        d="m33 24.0078c0-.2661-.1129-.4696-.3065-.6419l-12.2096-11.1154c-.1613-.1566-.371-.2505-.6291-.2505-.4677 0-.8548.3601-.8548.8297 0 .2349.0968.4384.2419.5793l11.6452 10.5988-11.6452 10.5832c-.1451.1409-.2419.3444-.2419.5793 0 .4853.3871.8297.8548.8297.2581 0 .4678-.0939.6291-.2505l12.2096-11.1154c.1936-.1723.3065-.3758.3065-.6263z"
        className="text-highlightBlueC3"
        fill="currentColor"
      />
    </svg>
  );
}

export default function SliderNavigation() {
  const t = useTranslate();
  const { canSlidePrevious, canSlideNext, slidePrevious, slideNext } = useSliderNavigation();

  return (
    <div className={styles.root}>
      {canSlidePrevious && (
        <div className={styles.previous}>
          <button className={styles.button} onClick={slidePrevious} aria-label={t('slider__previous')} type="button">
            <Arrow className="rotate-180 transform-gpu" />
          </button>
        </div>
      )}

      {canSlideNext && (
        <div className={styles.next}>
          <button className={styles.button} onClick={slideNext} aria-label={t('slider__next')} type="button">
            <Arrow />
          </button>
        </div>
      )}
    </div>
  );
}
