import { ComponentProps } from 'react';
import GridSlider, { GridSliderProps } from 'src/components/common/grid-slider';
import { SliderSlide } from 'src/components/common/slider';
import ContainerGrid from 'src/components/container-grid';
import HeadingSection from 'src/components/heading-section';
import { NodeTeaser } from 'src/components/node/node-teaser';

type NodeTeaserProps = ComponentProps<typeof NodeTeaser>['node'];

type ContentSliderProps = {
  items: NodeTeaserProps[];
  /**
   * Unique id for the slider
   * Required to be able to store slider state in memory cache and to have a unique data-test attribute
   * @example 'my-favorites'
   */
  id: string;
  pathname?: string;
  headline: string;
};

/**
 * Helper function to pick correct grid Slider size
 */
function sliderGridSize(node: NodeTeaserProps): GridSliderProps['teaserSize'] {
  const teaserNode = node.__typename === 'Favorite' || node.__typename === 'PlaybackProgress' ? node.content : node;

  switch (teaserNode.__typename) {
    case 'Album':
    case 'Track': {
      return 'square-large';
    }
    case 'Artist':
    case 'Group':
    case 'Partner': {
      return 'square-medium';
    }
    default: {
      return 'rectangle-medium';
    }
  }
}

/**
 * A generic content slider component
 *
 * @param items slider items of supported content types
 * @param headline headline to display above the slider
 * @param pathname link to the full list of the items
 * @example <ContentSlider items={sliderItems} headline="My Favorites" pathname="/my-stage/favorites" />;
 */
export default function ContentSlider({ items, pathname, headline, id }: ContentSliderProps) {
  const sliderItems = items || [];
  const firstItemNode = sliderItems.length > 0 ? sliderItems[0] : undefined;
  const gridSliderSize = firstItemNode ? sliderGridSize(firstItemNode) : undefined;

  // Don’t render anything if there are no items
  if (sliderItems.length === 0) return null;

  return (
    <div data-test={`content-slider:${id}`}>
      <ContainerGrid>
        <header className="col-span-full mb-4">
          <HeadingSection label={headline} url={pathname} />
        </header>
      </ContainerGrid>
      <GridSlider teaserSize={gridSliderSize ?? 'rectangle-medium'} id={id}>
        {sliderItems.map((node, index) => (
          <SliderSlide key={`${node.id}${index}`} className="my-3 select-none">
            <NodeTeaser node={node} />
          </SliderSlide>
        ))}
      </GridSlider>
    </div>
  );
}
