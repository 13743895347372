import { CuratedContentContainerFieldsFragment } from 'generated/graphql';
import { isContentAvailable } from 'src/utilities/content-availability-helpers';

/**
 * Filters out unavailable content from sliders based on geo-blocking rules and publish dates
 */
export function availableContentSliders(
  sliders: CuratedContentContainerFieldsFragment[],
  options: Parameters<typeof isContentAvailable>[1],
): CuratedContentContainerFieldsFragment[] {
  return sliders.map((slider) => {
    if (slider.__typename === 'ListSlider') {
      return {
        ...slider,
        items: slider.items.filter((item) => isContentAvailable(item, options)),
      };
    }
    return slider;
  });
}

/**
 * Returns true if the slider should be indexable by search engines based on the number of items
 */
export function isSliderIndexable(totalItems: number): boolean {
  return totalItems > 3;
}
