import { CuratedContentContainerFieldsFragment } from 'generated/graphql';
import ContentSlider from 'src/components/content-slider';
import { isSliderIndexable } from 'src/utilities/slider-helpers';
import { getNodePath } from 'src/utilities/url-helpers';

type ContentContainerSliderProps = {
  slider: Extract<CuratedContentContainerFieldsFragment, { __typename: 'ListSlider' }>;
};

/**
 * React component displaying a `Slider` ContentContainer datatype.
 * Always implemented by a `ContentContainers` component among other
 * `ContentContainerSlider` and `ContentContainerBanner` children.
 *
 * @param slider Slider ContentContainer datatype
 * @example <ContentContainerSlider slider={contentContainerItem} />;
 */
export default function ContentContainerSlider({ slider }: ContentContainerSliderProps) {
  const { id, headline, items } = slider;
  // Only link to the slider if it is indexable by search engines
  const pathname = isSliderIndexable(items.length) ? getNodePath(slider) : undefined;

  // Don’t render anything if there are no items
  if (items.length === 0) return null;

  return (
    <div className="py-8">
      <ContentSlider headline={headline} pathname={pathname} items={items} id={id} />
    </div>
  );
}
