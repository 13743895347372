import BigChevronIcon from '@stageplus/icons/react/big-chevron';
import SmallChevronIcon from '@stageplus/icons/react/small-chevron';
import Link from 'next/link';
import useTranslate from 'src/hooks/use-translate';

type HeadingSectionProps = {
  label: string;
  url?: string;
};

/**
 * Renders a title component to be used in the `HeadingSection` component.
 * A Chevron icon (›) is added to the right of the title on non-desktop breakpoints.
 */
function HeadingSectionTitle({ label, url }: HeadingSectionProps) {
  return (
    <h2 className="dg-text-light-2-uc">
      {url ? (
        <Link href={url} prefetch={false} className="flex items-center">
          {label}
          <BigChevronIcon className="ml-2 text-brandYellowC1 xl:hidden" />
        </Link>
      ) : (
        label
      )}
    </h2>
  );
}

/**
 * Renders a show more link component to be used in the `HeadingSection` component.
 */
function HeadingSectionShowMore({ url }: Required<Pick<HeadingSectionProps, 'url'>>) {
  const t = useTranslate();

  return (
    <Link href={url} prefetch={false} className="dg-text-regular-6 flex items-center hover:text-brandYellowC1">
      {t('slider__show_more')}
      <SmallChevronIcon className="text-brandYellowC1" />
    </Link>
  );
}

/**
 * Displays a title for a section of content. Usually in combination with a GridSlider component.
 * On desktop, an extra "Show More" link is displayed to the right of the title.
 */
export default function HeadingSection({ label, url }: HeadingSectionProps) {
  return (
    <div className="flex items-center justify-between" data-test="heading-section">
      <HeadingSectionTitle label={label} url={url} />
      {url && (
        <div className="hidden xl:block">
          <HeadingSectionShowMore url={url} />
        </div>
      )}
    </div>
  );
}
