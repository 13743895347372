import { CuratedContentContainerFieldsFragment } from 'generated/graphql';
import ContentContainerBanner from 'src/components/content-container-banner';
import ContentContainerSlider from 'src/components/content-container-slider';

type CuratedContentContainerListProps = {
  items: CuratedContentContainerFieldsFragment[];
};

/**
 * React component displaying a list of `CuratedContentContainer` datatypes.
 * A list item can be either a Slider or a Banner.
 *
 * @param items Array of ContentContainer datatypes
 * @example <CuratedContentContainerList items={contentContainers} />;
 */
export default function CuratedContentContainerList({ items }: CuratedContentContainerListProps) {
  return (
    <>
      {items.map((item, index) => {
        switch (item.__typename) {
          case 'ListSlider': {
            return <ContentContainerSlider key={item.id} slider={item} />;
          }
          case 'Banner': {
            return <ContentContainerBanner key={index} banner={item} />;
          }
          default: {
            throw new Error('unknown ContentContainer type');
          }
        }
      })}
    </>
  );
}
