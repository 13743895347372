import { useEffect, useMemo, useState } from 'react';
import { useSlider } from 'src/components/common/slider';

/**
 * Returns number of slides in view of the slider within the current context.
 */
export default function useSliderResponsive() {
  const [slidesPerGroup, setSlidesPerGroup] = useState<number>(1);
  const { slider } = useSlider();

  useEffect(() => {
    function calculate() {
      if (!slider) return;
      const wrapperWidth = slider.wrapperEl?.clientWidth;
      const wrapperMarginLeft = Number.parseInt(window.getComputedStyle(slider.wrapperEl).marginLeft);
      const slideWidth = slider.slides?.length > 0 && slider.slides[0].clientWidth;
      if (wrapperWidth && slideWidth) {
        setSlidesPerGroup(Math.floor((wrapperWidth - wrapperMarginLeft) / slideWidth));
      }
    }
    // run once
    calculate();
    // run on resize
    window.addEventListener('resize', calculate);
    // clean up
    return () => {
      window.removeEventListener('resize', calculate);
    };
  }, [slider]);

  return useMemo(
    () => ({
      slidesPerGroup,
    }),
    [slidesPerGroup],
  );
}
