import clsx from 'clsx';
import styles from 'src/components/common/grid-slider.module.css';
import { SliderProvider, Slider, SliderProps } from 'src/components/common/slider';
import useSliderResponsive from 'src/hooks/use-slider-responsive';

export type GridSliderProps = {
  teaserSize: 'square-medium' | 'square-large' | 'rectangle-medium'; // size of items in grid, see CSS module for more details
} & SliderProps;

const classNameSizeMap: Record<GridSliderProps['teaserSize'], string> = {
  'square-medium': styles['square-medium'],
  'square-large': styles['square-large'],
  'rectangle-medium': styles['rectangle-medium'],
};

/**
 * This component extends a normal Slider component and fits the containing
 * slides into the existing grid structure.
 */
function GridSliderContent({ children, teaserSize, ...rest }: GridSliderProps) {
  const { slidesPerGroup } = useSliderResponsive();

  return (
    <Slider
      options={{
        slidesPerView: 'auto',
        slidesPerGroup,
      }}
      className={clsx(styles.slider, classNameSizeMap[teaserSize])}
      {...rest}
      withNavigation
    >
      {children}
    </Slider>
  );
}

/**
 * Default export wrapping GridSlider inside a SliderProvider so we have access
 * to the context.
 *
 * @note GridSlider should never be wrapped inside a ContainerGrid!
 */
export default function GridSlider(props: GridSliderProps) {
  return (
    <SliderProvider>
      <GridSliderContent {...props} />
    </SliderProvider>
  );
}
