import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

type PageHref = string;
type CarouselId = string;

// cache all slider scroll positions, so we can re-create them later
const carouselPositions: Record<PageHref, Record<CarouselId, number>> = {};

const storeSliderPosition = ({ href, id, slideIndex }: { href: string; id: string; slideIndex: number }) => {
  // store the slider positions in the memory cache
  if (!carouselPositions[href]) {
    carouselPositions[href] = {};
  }
  carouselPositions[href][id] = slideIndex;
};

const getStoredSliderPosition = ({ href, id }: { href: string; id: string }) => carouselPositions[href]?.[id] || 0;

/**
 * remember where the slider was at the last time user has seen it
 *
 * @param id - reference to the slider id on the page
 * @example
 * const [ initialSlide, setInitialSlide ] = useCarouselMemory(id);
 * ...
 * return (
 *   <Slider initialSlide={initialSlide} afterChange={(index:number) => setInitialSlide(index)} />
 * )
 */

const useSliderMemory = (id: CarouselId): [number, React.Dispatch<number>] => {
  const { asPath: href } = useRouter();
  const [carouselPosition, setCarouselPosition] = useState(getStoredSliderPosition({ href, id }));
  useEffect(() => {
    // store position in a global object or local storage if needed
    storeSliderPosition({ href, id, slideIndex: carouselPosition });
  }, [carouselPosition, id, href]);
  return [carouselPosition, setCarouselPosition];
};

export default useSliderMemory;
